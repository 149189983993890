import { graphql, PageProps } from 'gatsby'
import React, { FC, useEffect } from 'react'
import { routes } from '../routes'
import { LangRedirectQuery } from '../graphqlTypes'

export const query = graphql`
  query LangRedirect {
    site {
      siteMetadata {
        locales
        defaultLocale
      }
    }
  }
`

const LangRedirect: FC<PageProps<LangRedirectQuery>> = ({
  data: {
    site: {
      siteMetadata: { locales, defaultLocale },
    },
  },
}) => {
  useEffect(() => {
    const routesByPath: Record<string, string> = {}

    for (const [route, paths] of Object.entries(routes)) {
      for (const path of Object.values(paths)) {
        routesByPath[path] = route
      }
    }

    let languages = []

    if (typeof navigator !== 'undefined') {
      if (navigator.languages) {
        // chrome only; not an array, so can't use .push.apply instead of iterating
        for (let i = 0; i < navigator.languages.length; i++) {
          languages.push(navigator.languages[i])
        }
      }
      //@ts-ignore
      if (navigator.userLanguage) {
        //@ts-ignore
        languages.push(navigator.userLanguage)
      }
      if (navigator.language) {
        languages.push(navigator.language)
      }

      languages = languages.map(($) => $.split('-')[0])
    }

    let langMatch = defaultLocale

    if (languages.length > 0) {
      for (const lang of languages) {
        if (locales.includes(lang)) {
          langMatch = lang
          break
        }
      }
    }

    const pathname = (() =>
      document.location.pathname[document.location.pathname.length - 1] === '/'
        ? document.location.pathname
        : document.location.pathname + '/')()

    document.location.href = `/${langMatch}${
      routes[routesByPath[pathname]][langMatch]
    }`
  }, [defaultLocale, locales])

  return <></>
}

export default LangRedirect
