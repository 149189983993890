export const routes: Record<string, Record<'en' | 'fr', string>> = {
  '/': {
    en: '/',
    fr: '/',
  },
  '/services/': {
    en: '/services/',
    fr: '/services/',
  },
  '/esg/': {
    en: '/esg/',
    fr: '/esg/',
  },
  '/jobs/': {
    en: '/jobs/',
    fr: '/emplois/',
  },
  '/contact/': {
    en: '/contact/',
    fr: '/contact/',
  },
  '/404/': {
    en: '/404/',
    fr: '/404/',
  },
}
